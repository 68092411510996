import React, { useRef } from "react";
import CanvasAnimation from "./Canvas";
import { useBetContext } from "./ContextAndHooks/BetContext";
import ReactAudioPlayer from "react-audio-player";
import backgroundSound from "./sound/background.mp3";
import { useSettingContext } from "./ContextAndHooks/SettingContext";
// import { deleteData } from "./api/ClientFunction";
import "./New.css";
const StageBoard = () => {
  const stateRef = useRef(null);
  const { state } = useBetContext();
  const { planeCrashed, gameStarted, planeValue } = state;
  const { sound } = useSettingContext().state;
  return (
    <div className="stage-board" ref={stateRef}>
      <div className="counter-num text-center" id="auto_increment_number_div">
        {planeCrashed && (
          <div
            className="secondary-font f-40 flew_away_section mb-2"
            style={{ fontWeight: "bold", fontFamily: "sans-serif" }}
          >
            FLEW AWAY!
          </div>
        )}
        {gameStarted && (
          <>
            <div
              id="auto_increment_number"
              className={`${planeCrashed ? "text-danger" : "text-cyan"}`}
            >
              {planeValue}
              <span>X</span>
            </div>
            {sound && !planeCrashed && (
              <ReactAudioPlayer src={backgroundSound} autoPlay={true} />
            )}
          </>
        )}
      </div>
      <img src="images/bg-rotate-old.svg" className="rotateimage" />
      <CanvasAnimation stateRef={stateRef} />
    </div>
  );
};

export default StageBoard;
